import Cookies from 'js-cookie'

export default {
    methods: {
        async storeUserAttributionToServer() {
            try {
                let adsData = localStorage.getItem('ads_data')
                if (adsData) {
                    adsData = JSON.parse(adsData)
                }
                let fbp = localStorage.getItem('fbp')
                let fbc = localStorage.getItem('fbc')
                let amplitudeId = localStorage.getItem('amplitudeId')
                if (adsData || fbp || fbc || amplitudeId) {
                    await this.$axios.post('attribution', {
                        fbp: fbp,
                        fbc: fbc,
                        amplitudeId: amplitudeId,
                        ads_data: adsData,
                    })
                    localStorage.removeItem('ads_data')
                    localStorage.removeItem('fbp')
                    localStorage.removeItem('fbc')
                    localStorage.removeItem('amplitudeId')
                }
            } catch (err) {
                console.error(err)
            }
        },
        storeUserAttributionToLocalStorage() {
            let searchParams = new URLSearchParams(window.location.search)
            if (searchParams.get('utm_source')) {
                let adsData = {}
                for (const [key, value] of searchParams.entries()) {
                    adsData[key] = value
                }
                
                if (typeof localStorage !== 'undefined') {
                    localStorage.setItem('ads_data', JSON.stringify(adsData))
                } else {
                    console.warn('localStorage is not available')
                }
            }

            localStorage.setItem('fbp', this.fbp)
            localStorage.setItem('fbc', this.fbc)
            localStorage.setItem('amplitudeId', amplitude.getDeviceId())
        },
    },
}
